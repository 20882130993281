import { mount, Wrapper } from '@vue/test-utils';
import DeliveryRecalculate from '@/components/popup/claim/ClaimPopup/DeliveryRecalculate.vue';
import { propsData } from '@/components/popup/claim/ClaimPopup/__tests__/mocks/DeliveryRecaluclate';
import { Vue } from 'vue/types/vue';

const mocks = {
  $t: () => '다국어',
};

const getTextDecorationValue = (wrapper: Wrapper<Vue>) =>
  (wrapper.element as HTMLElement).style.getPropertyValue('text-decoration');

// TODO: ToolTip쪽에서 unknwon custom element error남
//https://michaelnthiessen.com/solve-unknown-custom-element-vue/
describe('DeliveryRecalculate.vue', () => {
  let wrapper;
  beforeAll(() => {
    wrapper = mount(DeliveryRecalculate, {
      mocks,
      propsData: { ...propsData },
    });
  });

  describe('렌더링 테스트', () => {
    test('배송비 차액 컴포넌트(TdDeliveryDifference)가 정상 렌더링 되어야함.', () => {
      const TdDeliveryDifference = wrapper.findComponent({ name: 'td-delivery-difference' });

      expect(TdDeliveryDifference.exists()).toBe(true);
    });
  });

  describe('취소 클레임 삭선 테스트', () => {
    test.each([
      ['BUYER', 'false', 35000, 0, 35000],
      ['SELLER', 'true', 35000, 0, 35000],
      ['SELLER', 'false', 35000, 0, 35000],
      ['BUYER', 'false', 35000, 35250, -250],
      ['SELLER', 'false', 35000, 35250, -250],
      ['BUYER', 'false', 35000, 35250, 0],
      ['SELLER', 'true', 35000, 35250, 0],
      ['SELLER', 'false', 35000, 35250, 0],
      ['BUYER', 'false', 35250, 0, 35250],
      ['SELLER', 'true', 35250, 0, 35250],
      ['SELLER', 'false', 35250, 0, 35250],
    ])(
      '"귀책 == %s && 판매자 부담 %s" 일 때 전부 삭선처리 하지 않는다.(변경전 %i, 변경후 %i, 차액 %i)',
      async (
        responsibleObjectType,
        sellerPaysClaimedDelivery,
        beforeDeliveryAmt,
        afterDeliveryAmt,
        deliveryDifference,
      ) => {
        // given
        await wrapper.setProps({
          claimType: 'CANCEL',
          responsibleObjectType,
          sellerPaysClaimedDelivery: sellerPaysClaimedDelivery == 'true',
        });
        await wrapper.setData({
          deliveryAmtModified: { beforeDeliveryAmt, afterDeliveryAmt, deliveryDifference },
        });
        await wrapper.vm.$nextTick();

        // when
        const differenceLine = getTextDecorationValue(wrapper.find('[data-test="delivery-difference"]'));
        const beforeLine = getTextDecorationValue(wrapper.find('[data-test="before-delivery-amt"]'));
        const afterLine = getTextDecorationValue(wrapper.find('[data-test="after-delivery-amt"]'));

        //then
        expect(differenceLine).toBe('none');
        expect(beforeLine).toBe('none');
        expect(afterLine).toBe('none');
      },
    );

    test('"귀책 == SELLER && 판매자 부담 true" 일 때 "deliveryAdjustAmt !== refundDeliveryAmt"이면 배송비 차액에 삭선처리 한다.(변경전 35000, 변경후 35250, 차액 -250)', async () => {
      // given
      await wrapper.setProps({
        claimType: 'CANCEL',
        responsibleObjectType: 'SELLER',
        sellerPaysClaimedDelivery: true,
      });
      await wrapper.setData({
        deliveryAmtModified: { beforeDeliveryAmt: 35000, afterDeliveryAmt: 35250, deliveryDifference: -250 },
        amounts: { ...propsData.amounts, deliveryAdjustAmt: 250, refundDeliveryAmt: 0 },
      });
      await wrapper.vm.$nextTick();

      // when
      const differenceLine = getTextDecorationValue(wrapper.find('[data-test="delivery-difference"]'));
      const beforeLine = getTextDecorationValue(wrapper.find('[data-test="before-delivery-amt"]'));
      const afterLine = getTextDecorationValue(wrapper.find('[data-test="after-delivery-amt"]'));

      //then
      expect(differenceLine).toBe('line-through');
      expect(beforeLine).toBe('none');
      expect(afterLine).toBe('none');
    });
  });

  describe('반품 클레임 삭선 테스트', () => {
    test.each([
      ['BUYER', 'false', '>', 35000, 35250, -250],
      ['SELLER', 'false', '>', 35000, 35250, -250],
      ['BUYER', 'false', '<', 35000, 35250, -250],
      ['BUYER', 'false', '<', 35000, 35250, 0],
      ['SELLER', 'true', '<', 35000, 35250, 0],
      ['SELLER', 'false', '<', 35000, 35250, 0],
    ])(
      '"귀책 == %s && 판매자 부담 %s && 기집행배송비 %s 0" 일 때 전부 삭선처리 하지 않는다.(변경전 %i, 변경후 %i, 차액 %i)',
      async (
        responsibleObjectType,
        sellerPaysClaimedDelivery,
        compare,
        beforeDeliveryAmt,
        afterDeliveryAmt,
        deliveryDifference,
      ) => {
        // given
        const wrapper = mount(DeliveryRecalculate, {
          mocks,
          propsData: {
            ...propsData,
            claimType: 'RETURN',
            responsibleObjectType,
            sellerPaysClaimedDelivery: sellerPaysClaimedDelivery == 'true',
            amounts: { ...propsData.amounts, returnDelivery: { processedDeliveryAmt: compare === '>' ? 35250 : 0 } },
          },
        });
        await wrapper.setData({
          deliveryAmtModified: { beforeDeliveryAmt, afterDeliveryAmt, deliveryDifference },
        });
        await wrapper.vm.$nextTick();

        // when
        const differenceLine = getTextDecorationValue(wrapper.find('[data-test="delivery-difference"]'));
        const beforeLine = getTextDecorationValue(wrapper.find('[data-test="before-delivery-amt"]'));
        const afterLine = getTextDecorationValue(wrapper.find('[data-test="after-delivery-amt"]'));

        //then
        expect(differenceLine).toBe('none');
        expect(beforeLine).toBe('none');
        expect(afterLine).toBe('none');
      },
    );

    test('"귀책 == SELLER && 판매자 부담 true" 일 때 "deliveryAdjustAmt !== refundDeliveryAmt"이면 배송비 차액에 삭선처리 한다.(변경전 35000, 변경후 35250, 차액 -250)', async () => {
      // given
      await wrapper.setProps({
        claimType: 'RETURN',
        responsibleObjectType: 'SELLER',
        sellerPaysClaimedDelivery: true,
      });
      await wrapper.setData({
        deliveryAmtModified: { beforeDeliveryAmt: 35000, afterDeliveryAmt: 35250, deliveryDifference: -250 },
        amounts: { ...propsData.amounts, deliveryAdjustAmt: 250, refundDeliveryAmt: 0 },
      });
      await wrapper.vm.$nextTick();

      // when
      const differenceLine = getTextDecorationValue(wrapper.find('[data-test="delivery-difference"]'));
      const beforeLine = getTextDecorationValue(wrapper.find('[data-test="before-delivery-amt"]'));
      const afterLine = getTextDecorationValue(wrapper.find('[data-test="after-delivery-amt"]'));

      //then
      expect(differenceLine).toBe('line-through');
      expect(beforeLine).toBe('none');
      expect(afterLine).toBe('none');
    });
  });
});
