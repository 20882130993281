export const propsData = {
  claimType: 'CANCEL',
  claimStatusType: 'CANCEL_REQUEST',
  claimClassType: 'OPTION_CANCEL',
  amounts: {
    before: {
      order: {
        payAmt: 2670,
        accumulationPayAmt: 0,
        standardAmt: 3000,
        immediateDiscountAmt: 40,
        additionalDiscountAmt: 0,
        remainingPgPayAmt: 2670,
        remainingMainPayAmt: 2670,
        mainPayAmt: 2670,
      },
      delivery: {
        deliveryGroupAmounts: {
          deliveryAmt: 0,
          remoteDeliveryAmt: 0,
          prepaidDeliveryAmt: 0,
          prepaidRemoteDeliveryAmt: 0,
          payOnDeliveryAmt: 0,
          payOnRemoteDeliveryAmt: 0,
          alreadyPaidTotalDeliveryAmt: 0,
          totalDeliveryAmt: 0,
          totalPrepaidDeliveryAmt: 0,
        },
        deliveryAmt: 0,
        remoteDeliveryAmt: 0,
        totalDeliveryAmt: 0,
      },
      accumulation: { accumulationAmt: 0 },
      coupon: {
        cartCouponDiscountAmt: 0,
        productCouponDiscountAmt: 290,
        productCouponDiscountAmounts: [{ orderProductNo: 285695, couponDiscountAmt: 290 }],
      },
    },
    after: {
      order: {
        payAmt: 1590,
        accumulationPayAmt: 0,
        standardAmt: 1500,
        immediateDiscountAmt: 20,
        additionalDiscountAmt: 0,
        remainingPgPayAmt: 2670,
        remainingMainPayAmt: 2670,
        mainPayAmt: 1590,
      },
      delivery: {
        deliveryGroupAmounts: {
          deliveryAmt: 250,
          remoteDeliveryAmt: 0,
          prepaidDeliveryAmt: 250,
          prepaidRemoteDeliveryAmt: 0,
          payOnDeliveryAmt: 0,
          payOnRemoteDeliveryAmt: 0,
          alreadyPaidTotalDeliveryAmt: 0,
          totalDeliveryAmt: 250,
          totalPrepaidDeliveryAmt: 250,
        },
        deliveryAmt: 250,
        remoteDeliveryAmt: 0,
        totalDeliveryAmt: 250,
      },
      accumulation: { accumulationAmt: 0 },
      coupon: {
        cartCouponDiscountAmt: 0,
        productCouponDiscountAmt: 140,
        productCouponDiscountAmounts: [{ orderProductNo: 285695, couponDiscountAmt: 140 }],
      },
    },
    deliveryAdjustAmt: 0,
    refundDeliveryAmt: 0,
    adjustedAmounts: { payAmt: 1080, mainPayAmt: 1080, accumulationPayAmt: 0, additionalPayAmt: 0, claimAmt: -1080 },
    refund: {
      order: {
        payAmt: 1080,
        accumulationPayAmt: 0,
        standardAmt: 1500,
        immediateDiscountAmt: 20,
        additionalDiscountAmt: 0,
        remainingPgPayAmt: 0,
        remainingMainPayAmt: 0,
        mainPayAmt: 1080,
      },
      delivery: {
        deliveryGroupAmounts: {
          deliveryAmt: -250,
          remoteDeliveryAmt: 0,
          prepaidDeliveryAmt: -250,
          prepaidRemoteDeliveryAmt: 0,
          payOnDeliveryAmt: 0,
          payOnRemoteDeliveryAmt: 0,
          alreadyPaidTotalDeliveryAmt: 0,
          totalDeliveryAmt: -250,
          totalPrepaidDeliveryAmt: -250,
        },
        deliveryAmt: -250,
        remoteDeliveryAmt: 0,
        totalDeliveryAmt: -250,
      },
      accumulation: { accumulationAmt: 0 },
      coupon: {
        cartCouponDiscountAmt: 0,
        productCouponDiscountAmt: 150,
        productCouponDiscountAmounts: [{ orderProductNo: 285695, couponDiscountAmt: 150 }],
      },
    },
    initialDeliveryAmt: -250,
  },
  coupon: {
    cartCoupon: null,
    productCoupons: [
      {
        couponNo: 2427,
        couponIssueNo: 4425039,
        couponName: '10%할인 테스트 쿠폰',
        orderProductNo: 285695,
        restores: false,
      },
    ],
  },
  shipping: {
    originalShippingNo: 189865,
    shippingNo: 189865,
    deliveryGroupNo: 0,
    deliveryTemplateNo: 0,
    deliveryType: 'PARCEL_DELIVERY',
    requiresShipping: true,
    combined: false,
    divided: false,
    prepaid: true,
    hasAdjustedExchangeDeliveryAmt: false,
    address: {
      zipCd: '12345',
      address: '경기도||성남시',
      jibunAddress: '경기도 성남시 분당구 삼평동 629',
      name: 'NCP',
      detailAddress: ' 분당구 대왕판교로645번길 14 NHN Ent. 플레이뮤지엄',
      contact1: '010-7770-7777',
      contact2: '031-8038-0000',
    },
    invoiceNo: null,
    deliveryCompanyType: null,
  },
  responsibleObjectType: 'SELLER',
  sellerPaysClaimedDelivery: true,
};
